<template>
  <div class="event-marking-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column prop="source" label="事件類型" align="center" />
      <el-table-column prop="name" label="活動名稱" align="center" />
      <el-table-column prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">
            {{ scope.row.status.label }}
          </Tag>
        </template>
      </el-table-column>
      <el-table-column prop="startAt" label="開始時間" align="center" />
      <el-table-column prop="endAt" label="結束時間" align="center" />
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import store from '@/store'
import { get, map } from 'lodash'
import { formatDate } from '@/utils/date'
import { eventStatusConfig, eventTypeConfig } from '@/config/marketing'
import { DeleteEventMarketing } from '@/api/eventMarketing'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'EventMarkingTable',
  components: { EmptyBlock, DeleteDialog, Tag },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { source, status, name, startAt, endAt, id } = item
        return {
          id,
          source: eventTypeConfig[source]?.label,
          status: get(eventStatusConfig, status),
          name,
          startAt: formatDate(startAt),
          endAt: formatDate(endAt),
        }
      })
    })

    const onRowDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DeleteEventMarketing({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onRowEdit = (row) => {
      router.push({
        name: 'EventMarketingEdit',
        params: {
          id: row.id,
        },
      })
    }

    return {
      get,
      displayData,
      modal,
      onRowDelete,
      deleteRow,
      onRowEdit,
    }
  },
})
</script>
